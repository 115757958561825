import React from 'react';
import { useQuery } from '../hooks/useQuery';
import Calendar from "../images/calendar.svg";

function UpcomingEventsEs2() {
  const data = useQuery();
  const upcomings = data.upcomingEs2.nodes;
  let counter = 1;
  const totalEvents = 3;
  console.log(upcomings);

  // /*const todaysDate = new Date().toJSON().slice(0, 10).replace(/-/g, "/")*/
  // const todaysDate = new Date().toJSON();
  // const epochDate = new Date().getTime();
  // console.log(epochDate);
  // console.log(todaysDate);

  return (
    <div className="events-list columns is-centered" id="upcoming-events">
      {upcomings.map((event, index) => (
        <>
          {counter <= totalEvents ? (
            <div key={index} className="column">
              <React.Fragment
                className={`${(counter = counter + 1)}`}
              ></React.Fragment>
                <h4>{event.frontmatter.title}</h4>
                <div>
                    <img src={Calendar} alt="calendar icon" className='calendar' />
                    <p>{event.frontmatter.date2} | {event.frontmatter.start_time}</p>
                </div>
                <a href={event.frontmatter.slug} className="button">DETALLES</a>
            </div>
          ) : (
            <React.Fragment
              className={`{counter = counter -1}`}
            ></React.Fragment>
          )}
        </>
      ))}
    </div>
  );
}

export default UpcomingEventsEs2;